import './bootstrap';
import '../css/app.css';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy';
import Vapor from 'laravel-vapor';

/* fontawesome */
import '@fortawesome/fontawesome-free/css/all.css';

const APP_NAME = import.meta.env.VITE_APP_NAME || 'Synks';

const ASSETS_URL = import.meta.env.VITE_VAPOR_ASSET_URL || import.meta.env.ASSET_URL;

const PROGRESS_COLOR = '#4B5563'; // gray blue color hex

// For Vapor CloudFront asset URLs
Vapor.withBaseAssetUrl(ASSETS_URL);
window.Vapor = Vapor;

createInertiaApp({
  title: (title) => `${title} - ${APP_NAME}`,
  resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
  setup({ el, App, props, plugin }) {
    const vueApp = createApp({ render: () => h(App, props) });

    vueApp.mixin({
      methods: {
        asset: window.Vapor.asset,
      },
    });

    vueApp.use(plugin).use(ZiggyVue).mount(el);
  },
  progress: {
    color: PROGRESS_COLOR,
  }
});
